/* ContactUs.css */
.contact-us-page {
    padding: 20px;
    margin-top: 110px;
    font-family: Arial, sans-serif;
  }
  
  .contact-us-intro {
    margin-top: -20px;
    margin-bottom: 50px;
    padding: 20px;
    
    
  }
  .contact-us-intro h1 {
    text-align: center;
  }

 
  .contact-us-content {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  .contact-us-form,
  .contact-us-map {
    flex: 1;
    min-width: 300px;
  }
  
  .contact-us-form {
    max-width: 600px;
  }
  
  .contact-us-map {
    max-width: 600px;
  }
  
  .contact-us-form form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .contact-us-form label {
    margin-top: 10px;
  }
  
  .contact-us-form input,
  .contact-us-form textarea {
    width: 100%;
    max-width: 600px;
    padding: 10px;
    margin-top: 5px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 1rem;
  }
  
  .contact-us-form button {
    margin-top: 20px;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: #302b63;
    color: #fff;
    cursor: pointer;
    font-size: 1rem;
  }
  
  .contact-us-form button:hover {
    background-color: #4a47a3;
  }
  
  .contact-us-map iframe {
    width: 100%;
   height: 400px;
    border-radius: 8px;
  }
  