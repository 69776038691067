.services-container {
    display: flex;
    flex-direction: column;
    padding: 10px;
    background: #f5f5f5;
}

.services-container h1 {
    font-size: 36px;
    text-align: center;
    margin-top: 120px;
    margin-bottom: 20px;
    color: #302b63;
}

.services-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.service-item {
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin: 10px;
    padding: 10px;
    width: 150px;
    height: 230px; /* Increased height to accommodate text */
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    transition: transform 0.3s ease;
}

.service-item:hover {
    transform: translateY(-5px);
}

.service-image {
    width: 100%;
    height: 150px;
    object-fit: cover; /* Ensures image fits nicely */
    border-bottom: 1px solid #ddd;
    border-radius: 8px 8px 0 0;
}

.service-content {
    flex-grow: 1; /* Allows text content to fill available space */
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding: 5px;
}

.service-content h2 {
    font-size: 12px;
    color: #302b63;
    margin: 5px 0;
}

.service-content p {
    font-size: 12px;
    color: #555;
}

@media (max-width: 768px) {
    .services-container {
        width: 100%;
        margin: 20px auto;
    }
}
