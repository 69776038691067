/* src/components/Footer.css */
.footer {
  background: linear-gradient(135deg,#0576ae, #0909cf,#010f63);
    color: #fff;
    padding: 40px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  .footer-section {
    flex: 1;
    margin-right: 20px;
    min-width: 200px;
  }
  
  .footer-section h2 {
    font-size: 20px;
    margin-bottom: 15px;
    color: wheat; /* Accent color */
  }
  
  .footer-section p, .footer-section ul {
    margin: 0;
    padding: 0;
    font-size: 16px;
    font-weight: bold;
  }
  
  .footer-section ul {
    list-style: none;
  }
  
  .footer-section li {
    margin-bottom: 10px;
  }
  
  .footer-section a {
    color: #fff;
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  .footer-section a:hover {
    color: wheat; /* Accent color on hover */
  }

  .footer-bottom {
    background: linear-gradient(135deg,#023852, #000070,#010f63);
    padding: 10px;
    text-align: center;
    font-size: 0.9em;
  }
  
  .footer-bottom p {
    color: white;
    margin: 0;
  }
  
  .footer-bottom a {
    color: wheat;
    font-weight: bold;
    font-style: italic;
    text-decoration: none;
  }
  
  .footer-bottom a:hover {
    text-decoration: underline;
  }
  
  @media (max-width: 768px) {
    .footer {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .footer-section {
      margin-bottom: 20px;
    }
  }
  