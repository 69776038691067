/* src/components/TypingSlogans.css */
.typing-slogans {
    padding: 20px;
    background: #e0f7fa;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .typing-text {
    font-size: 24px;
    color: #00796b;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    border-right: 2px solid #00796b;
    animation: blink 1s step-end infinite; /* Slower blinking cursor */
    max-width: 100%;
  }
  
  .cursor {
    display: inline;
    color: #00796b;
    animation: blink 1s step-end infinite;
  }
  
  @keyframes blink {
    from, to { border-color: transparent; }
    50% { border-color: #00796b; }
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .typing-text {
      font-size: 20px; /* Adjust font size for tablets and smaller screens */
    }
  }
  
  @media (max-width: 480px) {
    .typing-text {
      font-size: 13px; /* Adjust font size for mobile phones */
    }
  }
  