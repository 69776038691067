.success-stories {
    margin: 20px;
    padding: 20px;
    margin-top: 90px;
    background-color: #f8f9fa;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.success-stories h2 {
    font-size: 2em;
    margin-bottom: 20px;
    color: #302b63;
    text-align: center;
}

.story {
    display: flex;
    flex-wrap: wrap; /* Allows items to wrap on smaller screens */
    align-items: center;
    margin-bottom: 20px;
    padding: 10px;
    border-bottom: 1px solid #ddd;
}

.story-image {
    max-width: 300px; /* Adjust size as needed */
    height: auto;
    margin-left: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.story-image1 {
    max-width: 100%; /* Adjust size as needed */
    height: auto;
    margin-left: 150px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.story-text {
    flex: 1;
    max-width: calc(100% - 320px); /* Ensures text takes up most space */
}

.story h3 {
    font-size: 1.5em;
    margin-bottom: 10px;
    color: #302b63;
}

.story p {
    font-size: 1.1em;
    line-height: 1.6;
    color: #666;
}

/* Responsive styles */
@media (max-width: 768px) {
    .story {
        flex-direction: column;
        align-items: center;
    }

    .story-image {
        margin-left: 0;
        margin-top: 10px;
        max-width: 100%; /* Ensures image scales down on small screens */
    }

    .story-image1 {
        margin-left: 0;
        margin-top: 10px;
        max-width: 100%; /* Ensures image scales down on small screens */
    }

    .story-text {
        max-width: 100%;
        text-align: center;
    }
}
