.about-founder {
    margin: 20px;
    padding: 20px;
    margin-top: 90px;
    background-color: #f8f9fa;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.about-founder h2 {
    font-size: 2em;
    margin-bottom: 20px;
    color: #302b63;
}

.about-founder p {
    font-size: 1.1em;
    line-height: 1.6;
    margin-bottom: 20px;
    color: #555;
}

.about-founder h3 {
    font-size: 1.5em;
    margin-top: 30px;
    margin-bottom: 10px;
    color: #302b63;
}

.about-founder ul {
    list-style-type: disc;
    margin-left: 20px;
    color: #555;
}

.about-founder li {
    margin-bottom: 10px;
}

.founder-content {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
}

.founder-text {
    flex: 1;
    min-width: 300px;
}

.founder-image {
    margin-left: 20px;
    text-align: center;
    max-width: 1000px;
}

.founder-image img {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
