.gallery {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: wheat;
  margin-top: 80px;
  background:#302b63;
}

.gallery h1{
  font-size: 30px;
  color: wheat;
  margin-bottom: -10px;
}
.gallery h2{
  
  color: wheat;
}

.event-section {
  color: wheat;
  font-size: 12px;
  margin: 10px ;
  text-align: center;
 
}

.event-images {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.gallery-image {
  width: 300px;
  height: 250px;
  margin: 5px;
  border: 3px solid #302b63;
  border-radius: 10px;
  cursor: pointer;
  transition: transform 0.3s, box-shadow 0.3s;
}

.gallery-image:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.modal {
  position: fixed;
  margin-top: 70px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgb(255, 255, 255);
  color: wheat;
  padding: 20px;
  border-radius: 8px;
  outline: none;
  z-index: 1000;
  max-width: 50vw;
  max-height: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative; /* Position relative to enable absolute positioning of close button */
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 999;
}

.modal-image {
  max-width: 100vw;
  max-height: 50vh;
}

.button-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: absolute;
  bottom: 20px; /* Adjust as needed */
}

.prev-button, .next-button {
  background-color: #302b63;
  color: wheat;
  border: none;
  padding: 10px;
  margin: 5px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.close-button {
  background-color: red;
  color: wheat;
  border: none;
  padding: 10px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s;
  position: absolute;
  top: 10px; /* Adjust as needed */
  right: 10px; /* Adjust as needed */
}

.close-button:hover {
  background-color: #f0f0f0;
  color: black;
}

.prev-button:hover, .next-button:hover {
  background-color: #f0f0f0;
  color: black;
}
@media (max-width: 1024px){
  .gallery{
    margin-top: 100px;
  }
}

@media (max-width: 768px) {
  
    .gallery{
      margin-top: 80px;
    }
  .gallery-image {
    width: 150px;
    height: 150px;
    margin: 5px;
    border: 3px solid #302b63;
    border-radius: 10px;
    cursor: pointer;
    transition: transform 0.3s, box-shadow 0.3s;
  }
  .modal {
    position: fixed;
    margin-top: 70px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 20px;
    border-radius: 8px;
    outline: none;
    z-index: 1000;
    max-width: 100%;
    max-height: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative; /* Position relative to enable absolute positioning of close button */
  }
}