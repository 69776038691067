.terms-conditions {
    max-width: 1300px;
    margin: 0 auto;
    padding: 20px;
    margin-top: 130px;
    line-height: 1.6;
  }
  
  .terms-conditions h1 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .terms-conditions h2 {
    margin-top: 20px;
    font-size: 1.5em;
    border-bottom: 2px solid #ddd;
    padding-bottom: 5px;
  }
  
  .terms-conditions p {
    margin-bottom: 15px;
  }
  
  .terms-conditions a {
    color: #007bff;
    text-decoration: none;
  }
  
  .terms-conditions a:hover {
    text-decoration: underline;
  }
  