.advisors-community {
    margin: 20px;
    padding: 20px;
    margin-top: 90px;
    background-color: #f8f9fa;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.advisors-community h2 {
    font-size: 2em;
    margin-bottom: 20px;
    color: #302b63;
    text-align: center;
}

.advisors-list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
}

.advisor-card {
    display: flex;
    align-items: flex-start;
    margin-bottom: 20px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 10px;
    background-color: #fff;
    width: 300px; /* Adjust size as needed */
}

.advisor-image {
    max-width: 700px; /* Adjust size as needed */
    height: auto;
    
    margin-right: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.advisor-info {
    flex: 1;
}

.advisor-info h3 {
    font-size: 1.5em;
    margin-bottom: 5px;
    color: #302b63;
}

.position, .former-position {
    font-style: italic;
    color: #777;
}

.advisor-info p {
    font-size: 1em;
    line-height: 1.6;
    color: #666;
}

/* Responsive styles */
@media (max-width: 768px) {
    .advisor-card {
        flex-direction: column;
        align-items: flex-start;
    }

    .advisor-image {
        margin-right: 0;
        margin-bottom: 10px;
        max-width: 350px; /* Adjust for smaller screens */
    }
}
