/* src/pages/PrivacyPolicy.css */
.privacy-policy {
    max-width: 1400px;
    margin: 0 auto;
    padding: 20px;
    margin-top: 130px;
    line-height: 1.6;
  }
  
  h1 {
    text-align: center;
    margin-bottom: 20px;
    color: #302b63;
  }
  
  h2 {
    margin-top: 30px;
    border-bottom: 2px solid #eee;
    color: #302b63;
    padding-bottom: 10px;
  }
  
  h3 {
    margin-top: 20px;
    color: #302b63;
  }
  
  p {
    margin: 10px 0;
  }
  
  a {
    color: brown;
    font-weight: bold;
    text-decoration: none;
  }
  
  a:hover {
    text-decoration: underline;
  }
  