/* src/components/Navbar.css */
.navbar {
  background: linear-gradient(135deg, #023852, #000070, #010f63);
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  height: 60px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
}

.navbar-header { display: flex; align-items: center; }

.logo {
  height: 30px; /* Smaller logo */
  width: auto;
  margin-right: 10px; /* Reduced margin */
  border-radius: 50%;
  transition: transform 0.3s ease;
}

.navbar h1, .navbar h3 {
  color: wheat;
  margin: 0;
  font-weight: bold;
  transition: transform 0.3s ease;
}

.navbar h1 { font-size: 18px; } /* Smaller title */
.navbar h3 { font-size: 12px; margin-left: -80px; margin-top: 50px} /* Smaller slogan */

.navbar:hover h1, .navbar:hover h3 { transform: scale(1.05); }

.toggle-button {
  display: none;
  background: none;
  border: none;
  cursor: pointer;
  padding: 10px;
  z-index: 10;
}

.toggle-button .bar {
  width: 25px; /* Smaller bars */
  height: 3px;
  background-color: wheat;
  margin: 5px 0;
  transition: all 0.3s ease;
}

.navbar.open .toggle-button .bar:nth-child(1) { transform: rotate(45deg) translateY(8px); }
.navbar.open .toggle-button .bar:nth-child(2) { opacity: 0; }
.navbar.open .toggle-button .bar:nth-child(3) { transform: rotate(-45deg) translateY(-8px); }

.dropdown-menu {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
}

.dropdown-menu li { position: relative; }

.dropdown-menu a {
  color: wheat;
  text-decoration: none;
  padding: 10px 15px; /* Smaller padding */
  display: block;
  font-size: 14px; /* Smaller font */
  font-weight: bold;
  transition: all 0.3s ease;
}

.dropdown-menu a:hover, .dropdown-menu .active a {
  background-color: rgba(255, 175, 27, 0.635);
  transform: translateY(-2px);
}

.dropdown {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background: linear-gradient(135deg, #023852, #000070, #010f63);
  list-style: none;
  padding: 0;
  margin: 0;
  transition: all 0.3s ease;
}

.dropdown-menu li:hover .dropdown { display: block; opacity: 1; transform: scaleY(1); }

.dropdown li a { padding: 10px 15px; font-size: 12px; } /* Smaller dropdown font */

/* Responsive Styles */
@media (max-width: 767px) {
  .navbar {
    
    flex-direction: column;
    align-items: flex-start;
    height: auto;
    padding: 10px;
    width: 97%;
    height:8vh
  }

  .navbar-header {
   
    width: 97%;
    display: flex;
    height:8vh;
    justify-content: space-between;
    align-items: center;
  }

  .logo {
    height: 50px;
    margin-left: 0;
  }

  .navbar h1 {
    font-size: 13px;
    margin-left: 0px;
    text-align: center;
  }

  .navbar h3 {
    font-size: 11px;
    margin-top: 40px;
  
    text-align: center;
  }

  .toggle-button {
    display: block;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
  }

  .dropdown-menu {
    display: none;
    flex-direction: column;
    width: 100%;
    background-color: #fff; /* Adjust background as needed */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    padding: 5px 0;
  }

  .dropdown-menu.open {
    display: flex;
  }

  .dropdown-menu li {
    width: 100%;
    text-align: center;
    padding: 10px 0;
  }

  .dropdown-menu a {
    display: block;
    padding: 10px;
    font-size: 14px;
    color: #333;
    text-decoration: none;
  }

  .dropdown-menu a:hover {
    background-color: #f0f0f0;
  }

  .dropdown {
    position: static;
    display: none;
    width: 100%;
  }

  .dropdown-menu li:hover .dropdown {
    display: block;
  }

  /* Ensure better touch responsiveness */
  button,
  a {
    touch-action: manipulation;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .navbar { height: 70px; }
  .logo { height: 50px; } /* Adjusted logo size for tablets */
  .navbar h1 { font-size: 15px; } /* Adjusted title size for tablets */
  .navbar h3 { font-size: 10px; margin-left: -100px; margin-top: 80px} /* Adjusted slogan size for tablets */
  .dropdown-menu a { padding: 10px 15px; font-size: 14px; } /* Adjusted font size for tablets */
  .dropdown li a { padding: 10px 15px; font-size: 12px; } /* Adjusted dropdown font size for tablets */
}

@media (min-width: 1024px) {
  .navbar { height: 80px; }
  .logo { height: 60px; } /* Adjusted logo size for desktops */
  .navbar h1 { font-size: 15px; } /* Adjusted title size for desktops */
  .navbar h3 { font-size: 10px; } /* Adjusted slogan size for desktops */
  .dropdown-menu a { padding: 12px 18px; font-size: 16px; } /* Adjusted font size for desktops */
  .dropdown li a { padding: 12px 18px; font-size: 14px; } /* Adjusted dropdown font size for desktops */
}