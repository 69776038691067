.news-scroller-wrapper {
  overflow: hidden;
  white-space: nowrap;
  background: #f5f5f5;
  padding: 10px 0;
}

.news-scroller {
  display: flex;
  animation: scroll-left 20s linear infinite;
  width: fit-content;
}

.news-item {
  display: inline-flex;
  flex-direction: column;
  min-width: 300px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-right: 20px;
}

.news-item img {
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-radius: 8px 8px 0 0;
}

.news-content {
  padding: 15px;
}

.news-content h3 {
  font-size: 20px;
  margin: 0;
}

.news-content p {
  font-size: 16px;
  margin-top: 5px;
}

@keyframes scroll-left {
  0% {
      transform: translateX(0);
  }
  100% {
      transform: translateX(-50%);
  }
}
