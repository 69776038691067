
  /* src/pages/Home.css */
  .home {
    
    padding: 20px;
    margin-top: 100px;
  }

  .home h1{
    text-align: center;
  }
  .doctors-box {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    justify-content: space-evenly;
    border: 1px solid #ddd;
    padding: 20px;
    border-radius: 8px;
    background-color: #f9f9f9;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
  }
  
 
  
  .doctors-box h2 {
    font-family: 'Arial', sans-serif;
    margin-bottom: 10px;
  }
  
  .doctors-box table {
    width: auto;
    border-collapse: collapse;
  }
  
  .doctors-box th, .doctors-box td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  .doctors-box th {
    background-color: wheat;
    font-family: 'Arial', sans-serif;
  }
  
  .doctors-box tr:nth-child(even) {
    background-color: #ddd;
  }
  
  .doctors-logo {
    
    height: 300px;
    width: auto; /* Adjust as needed */
    
  }
  .doctors-logo1 {
    
    height: 300px;
    width: 350px;

    
  }
  .home-carousel {
    margin-bottom: 20px;
  }
  
  .home-carousel img {
    width: 100%;
    height: 450px;
  }
  
  .carousel-caption {
    position: absolute;
    bottom: 20px;
    left: 20px;
    color: #fff;
    background: rgba(0, 0, 0, 0.5); /* Optional: to make text more readable */
    padding: 10px;
    border-radius: 5px;
  }
  .welcome-section {
    
    background: #f5f5f5;
  }
  
  .welcome-section h1 {
    font-size: 36px;
    color: #302b63; /* Accent color */
    margin-bottom: 20px;
  }
  
  .welcome-section p {
    font-size: 18px;
    line-height: 1.6;
    margin-bottom: 15px;
  }
  
  .welcome-section h2 {
    font-size: 28px;
    color: #302b63; /* Accent color */
    margin-top: 30px;
  }
  
  .welcome-section ul {
    list-style-type: none;
    padding: 0;
  }
  
  .welcome-section li {
    font-size: 18px;
    margin-bottom: 10px;
  }
  
  /* Media Queries for responsiveness */
@media (max-width: 768px) {

   /* src/pages/Home.css */
   .home {
    
    padding: 20px;
    margin-top: 120px;
  }
  .carousel-caption {
    
    bottom: 10px;
    left: 10px;
    font-size: 0.8em;
  }
  .home h1{
    margin-top: 50px;
    text-align: center;
    font-size: 25px;
  }
  .home-carousel img {
    width: 100%;
    height: 25vh;
  }
}


@media (min-width: 1440px){
  .home-carousel img {
    width: 100%;
    height: 600px;
  }
  
}


/* Event Section - Generic Design */
.event-section {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 30px auto;
  padding: 20px;
  max-width: 100%;
  background: #030d45;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  animation: fadeInUp 1.5s ease-in-out;
  gap: 20px;
}

/* Left Side - Event Poster */
.event-poster {
  flex: 1;
  display: flex;
  justify-content: center;
}

.event-poster img {
  width: 100%;
  max-width: 350px;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease-in-out;
}

.event-poster img:hover {
  transform: scale(1.05);
}

/* Right Side - Generic Event Info */
.event-info {
  flex: 1;
  text-align: left;
  animation: fadeInText 2s ease-in-out forwards;
}

.event-info h3 {
  font-size: 24px;
  font-weight: bold;
  color: #ffffff;
  margin-bottom: 10px;
}

.event-info p {
  font-size: 16px;
  color: #fffbfb;
  line-height: 1.5;
}

.event-btn {
  display: inline-block;
  margin-top: 10px;
  padding: 10px 20px;
  font-size: 16px;
  color: white;
  background: #d9534f;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s ease-in-out;
}

.event-btn:hover {
  background: #c9302c;
}

/* Mobile Styling */
@media (max-width: 768px) {
  .event-section {
    flex-direction: column;
    text-align: center;
    padding: 15px;
  }

  .event-poster {
    padding-right: 0;
    margin-bottom: 15px;
  }

  .event-poster img {
    max-width: 80%;
  }

  .event-info h3 {
    font-size: 20px;
  }

  .event-info p {
    font-size: 14px;
  }

  .event-btn {
    font-size: 14px;
    padding: 8px 16px;
  }
}

/* Animations */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInText {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
