/* LOADER 5 */

#loader-5 span{
    display: block;
    position: absolute;
    left: calc(50% - 20px);
    top: calc(50% - 20px);
    width: 20px;
    height: 20px;
    background: linear-gradient(135deg,#023852, #000070,#010f63);
  }
  
  #loader-5 span:nth-child(2){
    animation: moveanimation1 1s ease-in-out infinite;
  }
  
  #loader-5 span:nth-child(3){
    animation: moveanimation2 1s ease-in-out infinite;
  }
  
  #loader-5 span:nth-child(4){
    animation: moveanimation3 1s ease-in-out infinite;
  }
  
  @keyframes moveanimation1{
    0%, 100%{
      -webkit-transform: translateX(0px);
      -ms-transform: translateX(0px);
      -o-transform: translateX(0px);
      transform: translateX(0px);
    }
  
    75%{
      -webkit-transform: translateX(30px);
      -ms-transform: translateX(30px);
      -o-transform: translateX(30px);
      transform: translateX(30px);
    }
  }
  
  @keyframes moveanimation2{
    0%, 100%{
      -webkit-transform: translateY(0px);
      -ms-transform: translateY(0px);
      -o-transform: translateY(0px);
      transform: translateY(0px);
    }
  
    75%{
      -webkit-transform: translateY(30px);
      -ms-transform: translateY(30px);
      -o-transform: translateY(30px);
      transform: translateY(30px);
    }
  }
  
  @keyframes moveanimation3{
    0%, 100%{
      -webkit-transform: translate(0px, 0px);
      -ms-transform: translate(0px, 0px);
      -o-transform: translate(0px, 0px);
      transform: translate(0px, 0px);
    }
  
    75%{
      -webkit-transform: translate(30px, 30px);
      -ms-transform: translate(30px, 30px);
      -o-transform: translate(30px, 30px);
      transform: translate(30px, 30px);
    }
  }
  