.vips-opinions {
    margin: 20px;
    padding: 20px;
    margin-top: 90px;
    background-color: #f8f9fa;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.vips-opinions h2 {
    font-size: 2em;
    margin-bottom: 20px;
    color: #302b63;
    text-align: center;
}

.opinion-card {
    display: flex;
    align-items: flex-start;
    margin-bottom: 20px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 10px;
    background-color: #fff;
}

.vip-image {
    max-width: 120px; /* Adjust size as needed */
    height: auto;
    border-radius: 50%;
    margin-right: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.opinion-text {
    flex: 1;
}

.opinion-text h3 {
    font-size: 1.5em;
    margin-bottom: 5px;
    color: #302b63;
}

.position {
    font-style: italic;
    color: #777;
}

.opinion-text p {
    font-size: 1em;
    line-height: 1.6;
    color: #666;
}

/* Responsive styles */
@media (max-width: 768px) {
    .opinion-card {
        flex-direction: column;
        align-items: flex-start;
    }

    .vip-image {
        margin-right: 0;
        margin-bottom: 10px;
        max-width: 100px; /* Adjust for smaller screens */
    }
}
